<template>
  <div class="backend">
    <backend-left-bar :content="content"></backend-left-bar>
    <div class="backend-body">
      <router-view />
    </div>
  </div>
</template>

<script>
import BackendLeftBar from "./LeftBar";

export default {
  name: "Backend",
  components: {
    BackendLeftBar
  },
  mixins: [],
  props: [],
  data() {
    return {};
  },
  computed: {
    content() {
      let activeItem = "";
      if (
        this.$route.name == "teacherCoursesList" ||
        this.$route.name == "teacherEditCourse"
      ) {
        activeItem = "teacherCoursesList";
      }

      if (
        this.$route.name == "teacherDataList" ||
        this.$route.name == "teacherStudentProgress"
      ) {
        activeItem = "teacherDataList";
      }
      return activeItem;
    }
  },
  watch: {},
  mounted() {},
  methods: {}
};
</script>
<style scoped>
.backend {
  height: calc(100vh - 60px);
}
.backend-body {
  margin-left: 200px;
  height: 100%;
  overflow: auto;
}
</style>
